import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Spacer from "../../components/styles/Spacer"
import StripeStripesExample from "../../components/StripeStripesExample";

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query {
      stripeImage: file(relativePath: { eq: "stripe-homepage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Stripe stripes" />
      <h1>Build the stripes on Stripe</h1>


      <p>Hey, everyone!</p>
      <p>
        I saw the Stripe website recently, and I thought that the hero on there looked preeeetty slick. And what better way to learn how they do it than build our own! So, this is what we're going to build:
      </p>

      <Img fluid={data.stripeImage.childImageSharp.fluid} />

      <p>I originally thought there was some wizardry going on here, but the main bits are pretty simple: there's a background with a gradient, a grid with some other-colored blocks overlaying the grid, and then the entire thing gets a skew transformation. There's are some smaller details which we'll go over, but that's the gist!</p>

      <h2>Tools I'm using:</h2>

      <p>I'm using React and Styled Components to build this out. You can use whichever tools you'd like, as the code below will be pretty easy to parse for the important bits and transfer to your setup of choice.</p>

      <p>First, we'll build the basic markup. We'll need a overall container, a stripes container with some stripe `span`s inside of it, and a content container that we'll ignore until we get the stripes set up:</p>

      ```
        hello
      ```



      <Spacer size={200} />
      <StripeStripesExample />

      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>
    </Layout >
  )
}

export default SecondPage
