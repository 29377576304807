import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Spacer from "./styles/Spacer"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
`

const Stripes = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 1200px;
  background: linear-gradient(135deg, #16d2ff 31%, #e246ff 70%);
  transform: skewY(-12deg);
  transform-origin: 0;

  span {
    height: 20%;
    position: absolute;
    display: inline-block;
  }

  span:nth-child(1) {
    background-color: #16ffff;
    width: calc(100% / 6);
  }

  span:nth-child(2) {
    background-color: #14ebff;
    width: calc(100% / 2);
    margin-left: calc(100% / 6);
  }

  span:nth-child(3) {
    background-color: #03beff;
    width: calc(100% / 4);
    bottom: 0;
  }

  span:nth-child(4) {
    background-color: #de25ff;
    width: calc(100% / 3);
    margin-left: calc(2 * calc(100% / 3));
    bottom: 20%;
  }

  span:nth-child(5) {
    background-color: #ff00bf;
    background: linear-gradient(
      90deg,
      rgba(255, 0, 191, 0),
      rgba(255, 0, 191, 1)
    );
    width: calc(100% / 2);
    margin-left: calc(2 * calc(100% / 4));
    bottom: 0;
  }
`

const ContentContainer = styled.div`
  height: 100vh;
  min-height: 700px;
  position: relative;
`

const Content = styled.div`
  margin: 0 auto;
  width: 66.67%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  height: 30%;
`

const HeaderText = styled.h1`
  color: white;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
`

export default () => (
  <Container>
    <Stripes aria-hidden="true">
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </Stripes>
    <ContentContainer>
      <Content>
        <HeaderText>Hey, look at this thing we made</HeaderText>
      </Content>
    </ContentContainer>
  </Container>
)
